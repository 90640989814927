<template>
  <div>
    <div class="box" :style="{ width: width, height: height }">
      <img
        :src="imgUrl"
        alt=""
        :style="{
          width: width,
          height: 179 + 'px',
        }"
      />
      <div class="desc">
        <div class="title">
          {{ title }}
        </div>
        <div class="content">
          {{ content }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: () => "540px",
    },
    height: {
      type: String,
      default: () => "311px",
    },
    imgUrl: {
      type: String,
      default: () => "",
    },
    title: {
      type: String,
      default: () => "",
    },
    content: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.box {
  border: 1px solid #e2e4ea;
  display: grid;
  grid-template-rows: auto 1fr;
  margin-bottom: 80px;
  .desc {
    background: #f5f5f5;
    min-height: 132px;
    overflow: hidden;
    .title {
      font-size: 16px;
      color: rgba(51, 51, 51, 1);
      font-weight: bold;
      margin: 20px 0 0 20px;
    }
    .content {
      max-width: 490px;
      color: rgba(102, 102, 102, 1);
      font-size: 14px;
      margin: 10px 0 0 20px;
      line-height: 22px;
    }
  }
}
</style>
