<template>
  <div>
    <div class="digital">
      <div class="banner">
        <div class="bannerMain">
          <div class="title_main">金箱数云</div>
          <div class="line"></div>
          <div>
            供应链平台服务通过社会化协同，将上游与下游企业涉及的供应商、生产商、分销商等，以及金融、物流服
            务商等企业间的商流、物流、信息流、资金流形成一体化运作；通过开放的生态融合服务，为企业提供更多
            的供应链服务。从而不断提升企业供应链管理水平，保证产业链供应链稳定，实现敏捷供应、高效协同
          </div>
          <div
            style="display: flex; width: 300px; justify-content: space-between"
          >
            <div @click="goAbout" class="btn">方案咨询</div>
            <div class="btn_second" @click="toSaas">产品官网</div>
          </div>
        </div>
      </div>
      <div class="digitalMain">
        <div class="title"></div>
        <div class="boxes">
          <Box
            v-for="(item, index) in boxData"
            :key="index"
            :title="item.title"
            :content="item.content"
            :imgUrl="item.imgUrl"
          ></Box>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Box from "@/components/box";
export default {
  components: { Box },
  data() {
    return {
      boxData: [
        {
          imgUrl: require("@/assets/main/digital/1.png"),
          title: "数字化 | 社会化供应链服务",
          content:
            "以仓储服务为核心，端到端供应链执行，除支持企业内部的业务协同，也支持上下游企业的社会化协同；帮助产业链核心企业构建产业互联网运营平台, 为产业链上下游提供服务，保证产业链供应链稳定",
        },
        {
          imgUrl: require("@/assets/main/digital/2.png"),
          title: "开放融合",
          content:
            "供应链所有业务单据提供OpenAPI，实现与生态伙伴服务快速融合；通过开放的生态融合服务，为企业提供更多的供应链服务，可以快速实现供应链服务与生态伙伴服务融合兼容",
        },
        {
          imgUrl: require("@/assets/main/digital/3.png"),
          title: "灵活的业务配置",
          content:
            "灵活的业务流程配置，可以快速适应企业的业务变化；系统预置的标准流程，支持扩展补充；标准流程之外的流程，支持行业化、个性化",
        },
        {
          imgUrl: require("@/assets/main/digital/4.png"),
          title: "智能化的业务操作",
          content: "通过智能硬件、大数据分析等技术，提供智能供应链服务",
        },
        {
          imgUrl: require("@/assets/main/digital/5.png"),
          title: "责任到人的业务执行",
          content:
            "供应链业务发生前进行操作人认证、实时展现数据；操作人均进行实名认证，业务操作自动记录操作时间等数据，精准定位责任人",
        },
        {
          imgUrl: require("@/assets/main/digital/6.png"),
          title: "业务数据强监管",
          content:
            "建档、出入盘库等业务协同，通过单据追溯可以联查所有上下游单据，实时掌握各环节进展情况。各业务单据保存自动生成核算清单，实时更新业务数据；销售业务自动生成三方对接存证单据；实时数据透视、数据大屏分析",
        },
        {
          imgUrl: require("@/assets/main/digital/7.png"),
          title: "实时预警 | 风险可控",
          content:
            "针对供应链各业务环节关注点，提供风险预警服务。如档案创建、入库、出库、货值预警等；全局库存可视预警，核心企业可以实时掌握产业链实时全局动态，及时制定营销策略",
        },
      ],
    };
  },

  mounted() {},

  methods: {
    goAbout() {
      this.$router.push({ path: "/about", query: { ask: "true" } });
    },
    toSaas() {
      window.open("https://goldwms.cn");
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  width: 134px;
  height: 40px;
  background: rgba(234, 144, 82, 1);
  border-radius: 6px;
  line-height: 40px;
  text-align: center;
  font-size: 12px;
  color: rgba(255, 255, 255, 1);
  margin-top: 40px;
  cursor: pointer;
}
.btn_second {
  width: 134px;
  height: 38px;
  border: 1px solid #ea9052;
  border-radius: 6px;
  line-height: 40px;
  text-align: center;
  font-size: 12px;
  color: rgba(255, 255, 255, 1);
  margin-top: 40px;
  cursor: pointer;
}
.btn_second:hover {
  background: transparent;
}
.btn:hover {
  background: rgba(245, 166, 117, 1) !important;
}
.digital {
  /* width: 100vw; */
  height: 2085px;
  .banner {
    height: 400px;
    background: url("../../assets/main/digital/banner.png") no-repeat;
    background-position: center;
    background-size: cover;
    .bannerMain {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      overflow: hidden;
      .title_main {
        width: 129px;
        height: 32px;
        line-height: 32px;
        color: rgba(234, 144, 82, 1);
        font-size: 32px;
        font-weight: bolder;
        margin-top: 73px;
      }
      .line {
        width: 360px;
        height: 0;
        border-top: 2px solid rgba(251, 251, 251, 1);
        margin: 40px 0;
      }
      div:nth-child(3) {
        width: 658px;
        height: 80px;
        font-size: 14px;
        color: rgba(251, 251, 251, 1);
        line-height: 28px;
      }
      div:nth-child(4) {
      }
    }
  }
  .digitalMain {
    width: 1200px;
    height: 1685px;
    margin: 0 auto;
    overflow: hidden;
    .title {
      width: 272px;
      height: 41px;
      margin: 0 auto;
      margin-top: 40px;
      background: url("../../assets/main/digital/Functional-tittle.png")
        no-repeat;
    }
    .boxes {
      width: 1200px;
      height: 1483px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 41px;
      background: url("../../assets/goldmatrix/feature-back.png");
      background-size: cover;
      background-position: -400px;
    }
  }
}
</style>
